// Media Query Mixin
@mixin respond-to($breakpoint) {
    @if $breakpoint == mobile {
      @media only screen and (max-width: $small-screen) {
        @content;
      }
    }
    @else if $breakpoint == tablet {
      @media only screen and (min-width: $medium-screen) {
        @content;
      }
    }
    @else if $breakpoint == desktop {
      @media only screen and (min-width: $large-screen) {
        @content;
      }
    }
    @else {
      @warn "Invalid breakpoint: #{$breakpoint}. Please use 'mobile', 'tablet', or 'desktop'.";
    }
}