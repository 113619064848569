// variables.scss

// Colors
$primary-color: #ADF802;
$primary-color-dark: #689501;
$white: var(--white);
$black: var(--black);

// Fonts
$base-font-family: 'Helvetica Neue', Arial, sans-serif;
$heading-font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;

// Spacing
$base-spacing: 16px;

// Typography
$base-font-size: 16px;
$heading-1-size: 2em;
$heading-2-size: 1.5em;
$heading-3-size: 1.2em;

// Border radius
$border-radius: 4px;

// Box shadows
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$box-shadow--heavy: 0 2px 12px 3px #00000021;

// Breakpoints
$small-screen: 576px;
$medium-screen: 991px;
$large-screen: 1200px;

//Animations
$duration-fast: 0.2s;
$duration-normal: 0.5s;
$duration-slow: 1s;

$timing-function-in: ease-in;
$timing-function-out: ease-out;
$timing-function-in-out: ease-in-out;
$timing-function-linear: linear;


html[data-bs-theme='dark'] body {
    --white: #021808;
    --black: #fff;
}

html[data-bs-theme='light'] body {
    --white: #fff;
    --black: #021808;
}