@property --thickness {
    syntax: "<length>";
    initial-value: 1px;
    inherits: false;
}

.block-navigation {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    @include respond-to(mobile) {
        li {
            width: 100%;
        }
    }

    a {
        text-decoration-style: underline;
        text-decoration-thickness: var(--thickness);
        text-underline-offset: 0.1em;
        text-decoration-color: rgba(0, 0, 0, 0);
        transition: 
            color $duration-fast $timing-function-in-out,
            --thickness $duration-normal $timing-function-in-out, 
            text-decoration-color $duration-normal $timing-function-in-out;

        &:hover {
            color: $primary-color-dark;
            --thickness: 0.125rem;
            transition: --thickness $duration-fast $timing-function-in-out;
            text-decoration-color: $primary-color;
        }
    }
}