.card {
    border: none;
    height: 100%;
    box-shadow: $box-shadow--heavy;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
        img,
        video {
            scale: 1.05;
        }
    }

    &__header {
        position: relative;

        &__img {
            &__overlay {
                background: #0000007d;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
            }
        }

        &__content {
            b,
            strong {
                color: $primary-color;
            }

            & > div:first-of-type {
                & > p {
                    font-weight: bold;
                    font-size: 1.5rem;
                    line-height: 110%;
                }
            }
        }

        img {
            position: absolute;
            width: 100%;
            height: auto;
            z-index: -1;
            
            & + .card__header__content {
                position: relative;
                color: #fff;
                z-index: 2;
                padding: 2rem;
            }
        }
    }

    &__image {
        &__wrapper {
            overflow: hidden;
        }
    }

    &__content {
        margin-top: 2rem;
        padding: 0 2rem 2rem;

        h2 {
            color: $primary-color;
            font-size: 1.75rem;
        }

        b,
        strong {
            color: $primary-color;
            font-size: 2rem;
        }

        &__price {
            background: $primary-color;
            width: max-content;
            color: $white;
            padding: 0.5rem;
            border-radius: 4px;
            font-weight: bold;
            max-width: 100%;
        }
        .btn {
            z-index: 100;
            position: relative;
        }
    }

    img {
        aspect-ratio: 390/244;
        scale: 1;
        transition: scale $duration-fast $timing-function-in-out;
        object-fit: cover;
        max-width: 100%;
    }

    video {
        aspect-ratio: 390/244;
        scale: 1;
        transition: scale $duration-fast $timing-function-in-out;
        object-fit: cover;
        max-width: 100%;
        width: 100%;
        max-height: 244px;
    }
}