.hide-animation {
    position: fixed;
    top: 50%;
    max-height: 65%;
    left: 50%;
    width: 100%;
    border: 5px solid $primary-color;
    border-radius: 0.25rem;
    z-index: 2;
    opacity: 0;
    padding: 2rem;
    width: 80%;
    transform: translate(-50%,-50%);
    overflow-y: scroll;
    pointer-events: none;
    transition: 
        opacity $duration-fast $timing-function-in-out,
        background $duration-fast $timing-function-in-out,
        height $duration-normal $timing-function-in-out;
    
    @include respond-to(desktop) {
        width: 50%;
        max-height: 80%;
     }
    
    &.show {
        opacity: 1;
        background: $white;
        pointer-events: all;
    }
}