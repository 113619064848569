@import './normalize';

@import '/node_modules/bootstrap/dist/css/bootstrap.css';
@import '/node_modules/aos/dist/aos.css';
@import '../start.css';
@import './vars';

// generals
@import '../general/mixin';
@import '../general/utils';
@import '../general/footer';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.

@import '../components/image';
@import '../components/navigation';
@import '../components/card';
@import '../components/alert';
@import '../components/button';
@import '../components/animation';