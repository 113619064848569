.footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .menu-list {
        display: flex;
        list-style-type: none;
        gap: 1rem;
        margin: 0;
        align-items: center;
        flex-wrap: wrap;

        @include respond-to(mobile) {
            padding-left: 0;
        }
    }
}

a {
    transition: color $duration-fast $timing-function-in-out;
}

a:hover {
    color: $primary-color-dark;
}