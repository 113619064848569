.btn {
    box-shadow: none;
    border: 3px solid transparent;

    &:hover {
        background: $primary-color-dark;
        color: $white;
        border: 3px solid $black;
    }

    &--active {
        border: 3px solid $primary-color-dark;
        background: $primary-color-dark;
        color: $white;
    }

    &--green {
        background: $primary-color;
        padding: 0.5rem 1rem;
        color: #000000;
        font-weight: bold;
        border: 3px solid $primary-color;
        
        &:hover {
            background: transparent;
            border: 3px solid $primary-color;
            color: $black;
        }
    }

    &--ghost {
        background: transparent;
        border: 3px solid $primary-color;
        color: $black;
        
        &:hover {
            background: transparent;
            border: 3px solid transparent;
            color: $primary-color-dark;
        }
    }

    &--type {
        width: 100%;
        border-bottom: 1px solid rgba(255,255,255, .5);
        padding-bottom: 1rem;

        img {
            object-fit: cover;
            width: 100%;
            height: 200px;
        }
    }
}