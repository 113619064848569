.block-image {
    position: relative;

    &__content {
        position: absolute;
        padding-left: 2rem;
        top: 50%;  /* position the top  edge of the element at the middle of the parent */
        transform: translateY(-50%); /* This is a shorthand of translateX(-50%) and translateY(-50%) */
        color: #fff;
        z-index: 1;

        @include respond-to(tablet) {
            padding-left: 7.75rem;
        }

        &__header {
            b {
                color: $primary-color;
            }
        }
    }

    &__image {
        height: auto;
        object-fit: contain;

        &__overlay {
            content: '';
            background: rgba(0,0,0, 0.25);
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}

.react-photo-gallery--gallery {
    & > div {
        align-items: center;
        gap: 1rem;
    }

    img {
        object-fit: contain;
        width: max-content !important;
    }

    video {
        max-width: 31.25rem;
        max-height: 21rem;
    }
}