.pb-xlarge {
    padding-bottom: 3rem;

    @include respond-to(tablet) {
        padding-bottom: 7.75rem;
    }
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;

    p {
        display: none;
    }

    p:first-of-type {
        display: inline;
    }
}

html,body{ scroll-behavior: smooth}